module.exports = {
  title_redemption_date: "Redemption Date",
  title_voucher_code: "Voucher Code",
  title_category: "Category",
  title_voucher_name: "Voucher Name",
  title_point_redeemed: "Points Redeemed",
  title_voucher_expiry: "Voucher Expiry",
  title_status: "Status",
  button_title: "CLAIM REWARDS",
  description: "Use your points for voucher benefits.",
};
