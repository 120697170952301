module.exports = {
  button_upcoming: "Upcoming",
  button_history: "Past",
  button_all: "All",
  title_confirmation_no: "Confirmation No",
  title_booking_date: "Booking Date",
  title_arrival: "Arrival",
  title_departure: "Departure",
  title_hotel_name: "Hotel Name",
  title_status: "Status",
  explore_hotel: "EXPLORE HOTEL",
  reservation_emptynote: "Reserve now to start earning points.",
  reservation_footnote:
    "It may take up to two (2) days for your reservation to be updated here.",
};
